import React, { useEffect, useRef } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Img from 'gatsby-image';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Layout } from '@components';
//import { IconZap } from '@components/icons'; <IconZap />
import styled from 'styled-components';
import { theme, mixins, media, Main } from '@styles';
const { colors, fontSizes, fonts } = theme;
//comment
const StyledMainContainer = styled(Main)`
  & > header {
    text-align: center;
    margin-bottom: 100px;

    a {
      &:hover,
      &:focus {
        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>⚡</text></svg>")
            20 0,
          auto;
      }
    }
  }

  footer {
    ${mixins.flexBetween};
    margin-top: 20px;
    width: 100%;
  }
`;
const StyledGrid = styled.div`
  margin-top: 50px;

  .coins {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    position: relative;
    ${media.desktop`grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));`};
  }
`;
const StyledcoinsInner = styled.div`
  ${mixins.boxShadow};
  ${mixins.flexBetween};
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 2rem 1.75rem;
  height: 100%;
  border-radius: ${theme.borderRadius};
  transition: ${theme.transition};
  background-color: ${colors.lightNavy};
  header,
  a {
    width: 100%;
  }
`;
const Styledcoins = styled.div`
  transition: ${theme.transition};
  cursor: default;
  &:hover,
  &:focus {
    outline: 0;
    ${StyledcoinsInner} {
      transform: translateY(-5px);
    }
  }
`;
const StyledcoinsHeader = styled.div`
  ${mixins.flexBetween};
  margin-bottom: 30px;
`;
const StyledFeaturedImg = styled(Img)`
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
  border-radius: ${theme.borderRadius};
  position: relative;
  mix-blend-mode: multiply;
  filter: grayscale(100%) contrast(1) brightness(90%);
  ${media.tablet`
    object-fit: cover;
    width: auto;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(80%);
  `};
`;
const StyledImgContainer = styled.a`
  ${mixins.boxShadow};
  grid-column: 6 / -1;
  grid-row: 1 / -1;
  position: relative;
  z-index: 1;
  border-radius: ${theme.radius + 1}px;
  ${media.tablet`height: 100%;`};
  ${media.thone`
    grid-column: 1 / -1;
    opacity: 0.25;
  `};
  &:hover,
  &:focus {
    background: transparent;
    &:before,
    ${StyledFeaturedImg} {
      background: transparent;
      filter: none;
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    mix-blend-mode: screen;
  }
`;
const StyledcoinsName = styled.h5`
  margin: 0 0 10px;
  font-size: ${fontSizes.xxl};
  color: ${colors.lightestSlate};
`;
const StyledcoinsDescription = styled.div`
  font-size: 17px;
  color: ${colors.lightSlate};
`;
const StyledDate = styled.span`
  text-transform: uppercase;
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.xs};
  color: ${colors.lightSlate};
`;
const Styledcoinid = styled.ul`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-family: ${fonts.SFMono};
    font-size: ${fontSizes.xs};
    color: ${colors.green};
    line-height: 1.75;
    margin-right: 15px;
    &:last-of-type {
      margin-right: 0;
    }
    a {
      ${mixins.inlineLink};
    }
  }
`;

const coinPage = ({ location, data }) => {
  const coins = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <Helmet>
        <title>Numismatics | Alistair Eliott</title>
        <link rel="canonical" href="https://ake.nz/numismatics" />
      </Helmet>

      <StyledMainContainer>
        <header>
          <h1 className="big-title">Numismatics</h1>
          <p className="subtitle">
            <a
              href="https://en.wikipedia.org/wiki/Numismatics"
              target="_blank"
              rel="noopener noreferrer">
              a collection of coins
            </a>
          </p>
        </header>

        <StyledGrid>
          <div className="coins">
            {coins.length > 0 &&
              coins.map(({ node }, i) => {
                const { frontmatter } = node;
                const { cover, title, description, slug, date, coinid, coinnumber } = frontmatter;
                const d = new Date(date);

                return (
                  <Styledcoins key={i} tabIndex="0">
                    <StyledcoinsInner>
                      <header>
                        <Link to={slug}>
                          <StyledcoinsHeader>
                          <StyledImgContainer
                            target="_blank"
                            rel="nofollow noopener noreferrer">
                            <StyledFeaturedImg fluid={cover.childImageSharp.fluid} alt={title} />
                            </StyledImgContainer>
                          </StyledcoinsHeader>
                          <StyledcoinsName>{title}</StyledcoinsName>
                          <StyledcoinsDescription>{description}</StyledcoinsDescription>
                        </Link>
                      </header>
                      <footer>
                        <StyledDate>{`${d.toLocaleDateString()}`}</StyledDate>
                        <Styledcoinid>
                          {coinid.map((coinid, i) => (
                            <li key={i}>
                              <Link to={`/numismatics/coinid/${kebabCase(coinid)}/`}>#{coinid}</Link>
                            </li>
                          ))}
                        </Styledcoinid>
                      </footer>
                    </StyledcoinsInner>
                  </Styledcoins>
                );
              })}
          </div>
        </StyledGrid>
      </StyledMainContainer>
    </Layout>
  );
};

coinPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default coinPage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/coins/" }, frontmatter: { draft: { ne: true } } }
      sort: { fields: [frontmatter___coinnumber], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            cover {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100,) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            description
            slug
            date
            coinnumber
            coinid
            draft
          }
          html
        }
      }
    }
  }
`;
